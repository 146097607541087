import $ from '../core/Dom';

export default (el, props) => {
    const $el = $(el);
    const shareUrl = props.shareUrl;
    const $classicButtons = $el.find('[data-share-classic]');
    const $apiButton = $el.find('[data-share-api]');

    const init = () => {
        console.log(navigator.canShare);
        if (navigator.canShare) {
            $apiButton.removeClass('opacity-0');

            $apiButton.on('click', () => {
                share();
            });
        } else {
            $classicButtons.removeClass('hidden');
            $apiButton.addClass('hidden');

            $classicButtons.on('click', e => {
                e.preventDefault();

                const $link = $(e.triggerTarget);
                const left = (window.screen.width / 2) - (600 / 2);
                const top = (window.screen.height / 2) - (600 / 2);

                window.open($link.attr('href'), 'shareWindow', 'height=600,width=600, top=' + top + ',left=' + left + ',toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,directories=no,status=no');
            });
        }
    };

    const destroy = () => {

    };

    const share = () => {
        const title = document.querySelector('meta[property="og:title"]').content;
        const text = document.querySelector('meta[property="og:description"]').content;
        const url = shareUrl;

        console.log(url);
        
        try {
            navigator.share({ url });
        } catch (err) {
            console.error('Error: ' + err);
        }
    };

    return {
        init,
        destroy
    };
};
